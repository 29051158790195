import React, { useEffect, useRef } from 'react';
import './common.css';

const MatrixTitle = () => {
  const titleRef = useRef(null);
  const originalText = "CODE CONVERT";

  useEffect(() => {
    const titleElement = titleRef.current;
    if (!titleElement) return;

    const chars = "ｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝ";
    let frame = 0;
    const totalFrames = 45;
    const frameDelay = 1000 / 30;
    let animationFrame;

    const animateTitle = () => {
      const progress = frame / totalFrames;
      const text = originalText
        .split('')
        .map((char, index) => {
          if (index < progress * originalText.length) {
            return originalText[index];
          }
          return chars[Math.floor(Math.random() * chars.length)];
        })
        .join('');

      titleElement.textContent = text;

      if (frame < totalFrames) {
        frame++;
        animationFrame = setTimeout(() => requestAnimationFrame(animateTitle), frameDelay);
      }
    };

    const startAnimation = () => {
      frame = 0;
      requestAnimationFrame(animateTitle);
    };

    const timeoutId = setTimeout(startAnimation, 500);

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(animationFrame);
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  return (
    <div className="matrix-title">
      <h1 ref={titleRef}>{originalText}</h1>
    </div>
  );
};

export default MatrixTitle; 