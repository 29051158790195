import React, { useEffect, useRef } from 'react';

const MatrixSpiral = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrame;

    // Set canvas size
    const resize = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };
    resize();
    window.addEventListener('resize', resize);

    // Matrix characters
    const chars = '01';
    const fontSize = 14;
    ctx.font = `${fontSize}px monospace`;

    // Spiral animation
    let angle = 0;
    const animate = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const maxRadius = Math.min(centerX, centerY);

      // Draw spiral
      for (let i = 0; i < 50; i++) {
        const radius = (i * maxRadius / 50);
        const x = centerX + radius * Math.cos(angle + i * 0.2);
        const y = centerY + radius * Math.sin(angle + i * 0.2);
        
        ctx.fillStyle = `rgba(0, 255, 65, ${1 - i/50})`;
        ctx.fillText(
          chars[Math.floor(Math.random() * chars.length)],
          x,
          y
        );
      }

      angle += 0.05;
      animationFrame = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', resize);
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  return (
    <canvas 
      ref={canvasRef}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0
      }}
    />
  );
};

export default MatrixSpiral; 