import React, { useState, useEffect } from 'react';
import './common.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthContext';

const PromptHistory = ({ history, onPromptSelect }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { user, isInitialized } = useAuth();

  useEffect(() => {
    console.log('PromptHistory auth state:', {
      isAuthenticated: !!user,
      userId: user?.uid,
      historyLength: history?.length,
      isInitialized
    });
  }, [user, history, isInitialized]);

  if (!isInitialized) {
    return (
      <div className="prompt-history-section">
        <h3>Recent Prompts</h3>
        <div className="prompt-list">
          <div className="no-prompts">Loading...</div>
        </div>
      </div>
    );
  }

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    try {
      if (timestamp.toDate) {
        return timestamp.toDate().toLocaleString();
      }
      return new Date(timestamp).toLocaleString();
    } catch (error) {
      console.error('Error formatting timestamp:', error);
      return '';
    }
  };

  // Check if history exists and has items
  const hasHistory = Array.isArray(history) && history.length > 0;

  return (
    <div className="prompt-history-section">
      <h3 onClick={() => setIsExpanded(!isExpanded)} style={{ cursor: 'pointer' }}>
        Recent Prompts {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
      </h3>
      {isExpanded && (
        <div className="prompt-list">
          {user ? (
            hasHistory ? (
              history.map((item) => (
                <div 
                  key={item.id || item.timestamp}
                  className="prompt-item"
                  onClick={() => onPromptSelect(item)}
                >
                  <div className="prompt-text">{item.prompt}</div>
                  <div className="prompt-time">
                    {formatTimestamp(item.timestamp)}
                  </div>
                </div>
              ))
            ) : (
              <div className="no-prompts">
                No prompts yet. Try generating some code!
              </div>
            )
          ) : (
            <div className="no-prompts">
              <button 
                onClick={() => document.querySelector('.auth-button')?.click()} 
                className="login-prompt-button"
              >
                Sign in to save your prompts
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PromptHistory; 