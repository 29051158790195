import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import CodeConverter from './CodeConverter/CodeConverter';
import MatrixRain from './common/MatrixRain';

function MainPage() {
  const { user } = useAuth();

  return (
    <div className="main-container">
      <MatrixRain />
      <header className="matrix-title">
        <h1>Code Convert</h1>
      </header>
      <CodeConverter />
    </div>
  );
}

export default MainPage; 