import React, { useState, useRef, useEffect, useCallback } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../firebase';
import './CodeConverter.css';
import { FaCopy, FaPaste, FaTrash } from 'react-icons/fa';
import { addDoc, collection, serverTimestamp, query, where, orderBy, limit, getDocs, Timestamp, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast } from 'react-hot-toast';
import MatrixSpiral from '../common/MatrixSpiral';
import PromptHistory from '../common/PromptHistory';
import { useAuth } from '../../contexts/AuthContext';
import { getFormatSpecificPrompt } from '../../utils/promptUtils';
import { marked } from 'marked';
import systemsManager from '../../systems';

// Initialize functions with the correct region
const functions = getFunctions(app, 'us-west2');
const generateCode = httpsCallable(functions, 'generateCodeV3');

const DOQS_API_KEY_DEV = process.env.REACT_APP_DOQS_API_KEY_DEV;
const DOQS_API_KEY_LIVE = process.env.REACT_APP_DOQS_API_KEY_LIVE;
const DOQS_API_URL = 'https://api.doqs.dev/v1';

const DEV_MODE = process.env.NODE_ENV === 'development';
const API_ENDPOINT = DEV_MODE ? 
  'http://localhost:11434/api/generate' :  // Local Ollama
  'https://us-west2-contract-sender.cloudfunctions.net/generateCode';

// Add error boundary
const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleError = (error) => {
      console.error('Caught error:', error);
      setError(error);
      setHasError(true);
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  if (hasError) {
    return (
      <div className="error-boundary">
        <h2>Something went wrong</h2>
        <pre>{error?.message}</pre>
        <button onClick={() => window.location.reload()}>
          Reload Page
        </button>
      </div>
    );
  }

  return children;
};

const CodeConverter = () => {
  const { user } = useAuth();
  const [prompt, setPrompt] = useState('');
  const [outputFormat, setOutputFormat] = useState('image');
  const [aspectRatio, setAspectRatio] = useState('4:5');
  const [generatedCode, setGeneratedCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [recentPrompts, setRecentPrompts] = useState([]);
  const [changingOptions, setChangingOptions] = useState({
    outputFormat: false,
    aspectRatio: false
  });
  const [showPreview, setShowPreview] = useState(false);
  const [captureAnimation, setCaptureAnimation] = useState(false);
  
  const codeRef = useRef(null);
  const previewRef = useRef(null);

  const [files, setFiles] = useState({
    'content.md': '',
    'hasGraphics': false
  });

  const [activeFile, setActiveFile] = useState('index.html');

  const [optionsLoaded, setOptionsLoaded] = useState({
    outputFormat: false,
    aspectRatio: false
  });

  const [promptHistory, setPromptHistory] = useState([]);

  // Add state for preview
  const [previewUrl, setPreviewUrl] = useState(null);

  // Add keyword mappings for auto format selection
  const formatKeywords = {
    image: [
      'design', 'visual', 'art', 'graphic', 'flyer', 'poster', 
      'logo', 'banner', 'creative', 'beautiful', 'static', 
      'picture', 'image', 'photo', 'illustration'
    ],
    pdf: [
      'document', 'report', 'paper', 'text', 'article', 
      'publication', 'book', 'print', 'pdf', 'doc',
      'contract', 'agreement', 'legal', 'terms', 'proposal',
      'letter', 'form', 'template'
    ],
    gif: [
      'animation', 'motion', 'moving', 'animate', 'dynamic', 
      'interactive', 'gif', 'animated', 'movement', 'transition'
    ]
  };

  // Update the triggerOptionAnimation function
  const triggerOptionAnimation = async (optionType, oldValue, newValue) => {
    return new Promise((resolve) => {
      const select = document.getElementById(optionType);
      if (select) {
        select.value = newValue;
        animateTextReveal(select, newValue).then(() => {
          if (optionType === 'outputFormat') {
            setOutputFormat(newValue);
            if (newValue === 'gif') {
              setCaptureAnimation(true);
            }
          } else if (optionType === 'aspectRatio') {
            setAspectRatio(newValue);
          }
          resolve();
        });
      } else {
        resolve();
      }
    });
  };

  // Add this helper function at component level
  const animateOption = async (optionId, newValue) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%^&*';
    const element = document.querySelector(`#${optionId}`);
    if (!element) return;

    const overlay = document.createElement('div');
    overlay.className = 'scramble-text';
    element.parentElement.appendChild(overlay);

    let currentIndex = 0;
    const totalFrames = 10;
    const frameInterval = 50;

    for (let frame = 0; frame <= totalFrames; frame++) {
      let displayText = '';
      for (let i = 0; i < newValue.length; i++) {
        if (i < currentIndex) {
          // Show revealed character
          displayText += newValue[i];
        } else {
          // Show scrambled character
          displayText += chars[Math.floor(Math.random() * chars.length)];
        }
      }
      overlay.textContent = displayText;
      
      if (frame % 2 === 0 && currentIndex < newValue.length) {
        currentIndex++;
      }
      
      await new Promise(resolve => setTimeout(resolve, frameInterval));
    }

    // Show final text
    overlay.textContent = newValue;
    await new Promise(resolve => setTimeout(resolve, frameInterval));
    overlay.remove();
  };

  // Update the handlers to prevent animation on manual changes
  const handleOutputFormatChange = (e) => {
    setOutputFormat(e.target.value);
    if (e.target.value === 'gif') {
      setCaptureAnimation(true);
    } else {
      setCaptureAnimation(false);
    }
  };

  const handleAspectRatioChange = (e) => {
    setAspectRatio(e.target.value);
  };

  // Update the animateTextReveal function
  const animateTextReveal = async (element, finalText) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%^&*';
    let currentIndex = 0;
    const frameInterval = element.id === 'aspectRatio' ? 30 : 50;
    const revealSpeed = element.id === 'aspectRatio' ? 100 : 200;

    // Get the display text from the selected option
    const selectedOption = Array.from(element.options).find(opt => opt.value === finalText);
    const displayText = selectedOption ? selectedOption.text : finalText;

    // Create overlay
    const overlay = document.createElement('div');
    overlay.className = 'scramble-text';
    
    // Create container if it doesn't exist
    let container = element.parentElement;
    if (!container.classList.contains('select-container')) {
      container = document.createElement('div');
      container.className = 'select-container';
      element.parentNode.insertBefore(container, element);
      container.appendChild(element);
    }
    
    element.style.visibility = 'hidden';
    container.appendChild(overlay);

    // Position overlay
    const rect = element.getBoundingClientRect();
    overlay.style.width = `${rect.width}px`;
    overlay.style.height = `${rect.height}px`;
    overlay.style.left = '0';
    overlay.style.top = '0';
    overlay.style.padding = window.getComputedStyle(element).padding;
    overlay.style.fontSize = window.getComputedStyle(element).fontSize;

    const scrambleInterval = setInterval(() => {
      let scrambledText = '';
      for (let i = 0; i < displayText.length; i++) {
        if (i < currentIndex) {
          scrambledText += displayText[i];
        } else {
          scrambledText += chars[Math.floor(Math.random() * chars.length)];
        }
      }
      overlay.textContent = scrambledText;
    }, frameInterval);

    // Reveal text gradually
    const revealText = () => {
      return new Promise(resolve => {
        const revealInterval = setInterval(() => {
          currentIndex++;
          if (currentIndex > displayText.length) {
            clearInterval(revealInterval);
            clearInterval(scrambleInterval);
            element.style.visibility = 'visible';
            overlay.remove();
            resolve();
          }
        }, revealSpeed);
      });
    };

    await revealText();
  };

  // Update handlePromptChange to include format detection
  const handlePromptChange = (e) => {
    const newPrompt = e.target.value;
    setPrompt(newPrompt);

    // Check prompt for format keywords
    const promptLower = newPrompt.toLowerCase();
    
    // Format detection with priority for contract/legal documents
    if (promptLower.includes('contract') || 
        promptLower.includes('agreement') || 
        promptLower.includes('legal') || 
        promptLower.includes('document')) {
      console.log('Contract keyword detected - switching to PDF format');
      setOutputFormat('pdf');
      setAspectRatio('letter');
      return;
    }

    // Other format detection logic
    for (const [format, keywords] of Object.entries(formatKeywords)) {
      if (keywords.some(keyword => promptLower.includes(keyword))) {
        console.log(`Detected format: ${format} from keyword in prompt`);
        setOutputFormat(format);
        break;
      }
    }
  };

  const detectAnimation = (files) => {
    // Check CSS for animations
    const hasKeyframes = files['styles.css']?.includes('@keyframes');
    const hasTransition = files['styles.css']?.includes('transition');
    const hasAnimation = files['styles.css']?.includes('animation');
    
    // Check JavaScript for animation-related code
    const hasSetTimeout = files['script.js']?.includes('setTimeout');
    const hasSetInterval = files['script.js']?.includes('setInterval');
    const hasRequestAnimation = files['script.js']?.includes('requestAnimationFrame');

    return hasKeyframes || hasTransition || hasAnimation || 
           hasSetTimeout || hasSetInterval || hasRequestAnimation;
  };

  // Update handlePromptSelect to only update the prompt text
  const handlePromptSelect = (selectedPrompt) => {
    // Prevent default if it's a click event
    if (selectedPrompt.preventDefault) {
      selectedPrompt.preventDefault();
      return;
    }
    
    setPrompt(selectedPrompt.prompt || '');
    if (selectedPrompt.outputFormat) {
      setOutputFormat(selectedPrompt.outputFormat);
    }
    if (selectedPrompt.aspectRatio) {
      setAspectRatio(selectedPrompt.aspectRatio);
    }
  };

  // Move the generation logic to a separate function
  const [generationProgress, setGenerationProgress] = useState('');

  const handleGenerate = async () => {
    try {
      setIsGenerating(true);
      setError(null);
      setGeneratedCode('');
      setPdfPreview(null);
      
      const result = await generateCode({
        prompt,
        outputFormat,
        aspectRatio,
        convert: false
      });

      console.log('Generation response:', result);

      if (result?.data?.files?.['content.md']) {
        setGeneratedCode(result.data.files['content.md']);
        setFiles(result.data.files);
        
        // Save prompt to history
        if (user) {
          try {
            await addDoc(collection(db, 'prompts'), {
              userId: user.uid,
              prompt,
              timestamp: serverTimestamp(),
              outputFormat,
              aspectRatio
            });
            console.log('Prompt saved to history');
          } catch (err) {
            console.error('Error saving prompt:', err);
            toast.error('Failed to save prompt to history');
          }
        }
      } else {
        throw new Error('No data received from function');
      }
    } catch (err) {
      console.error('Generation error:', err);
      setError(err.message || 'Failed to generate code');
      toast.error(err.message || 'Failed to generate code');
    } finally {
      setIsGenerating(false);
    }
  };

  // Add state for PDF preview
  const [pdfPreview, setPdfPreview] = useState(null);

  // Update the handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.trim() || loading) return;

    try {
      setIsGenerating(true);
      setError(null);

      // Start performance tracking
      const perfOp = systemsManager.getSystem('performance').startOperation('generate_code');
      
      // Track analytics
      systemsManager.getSystem('analytics').trackEvent('code_generation_start', {
        prompt,
        outputFormat
      });

      // Generate code with error recovery
      const result = await systemsManager.getSystem('errorRecovery')
        .executeWithRecovery(async () => {
          return await generateCode({
            prompt,
            outputFormat,
            aspectRatio,
            convert: false
          });
        });

      // Cache result
      await systemsManager.getSystem('cache').set(
        `prompt:${prompt}`,
        result
      );

      // Track success
      systemsManager.getSystem('analytics').trackEvent('code_generation_success', {
        duration: systemsManager.getSystem('performance').endOperation(perfOp).duration
      });

      console.log('Generation response:', result);

      if (result?.data?.files?.['content.md']) {
        setGeneratedCode(result.data.files['content.md']);
        setFiles(result.data.files);

        // Save prompt to history with better error handling
        if (user) {
          try {
            console.log('Attempting to save prompt for user:', user.uid);
            
            // Create prompt document
            const promptRef = collection(db, 'prompts');
            const promptData = {
              userId: user.uid,
              prompt,
              timestamp: serverTimestamp(),
              outputFormat,
              aspectRatio
            };

            console.log('Saving prompt data:', promptData);
            const docRef = await addDoc(promptRef, promptData);
            console.log('Prompt saved successfully with ID:', docRef.id);

            // Force refresh prompt history
            const unsubscribe = await fetchPromptHistory();
            if (unsubscribe) unsubscribe();

            toast.success('Prompt saved to history');
          } catch (err) {
            console.error('Error saving prompt:', {
              error: err,
              code: err.code,
              message: err.message,
              user: user.uid
            });
            toast.error('Failed to save prompt to history');
          }
        } else {
          console.log('No user logged in - skipping prompt save');
        }
      }
    } catch (error) {
      // Track error
      systemsManager.getSystem('analytics').trackEvent('code_generation_error', {
        error: error.message
      });
      
      console.error('Generation error:', error);
      setError(error.message);
      toast.error(error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedCode);
  };

  // Update handlePaste to only update text and files
  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setGeneratedCode(text);
      
      // Parse the pasted text to update files state
      const blocks = text.split('```');
      const newFiles = { ...files };
      
      blocks.forEach((block, index) => {
        if (index === 0) return;
        
        const trimmedBlock = block.trim();
        if (!trimmedBlock) return;

        const firstLineBreak = trimmedBlock.indexOf('\n');
        if (firstLineBreak === -1) return;

        const blockType = trimmedBlock.substring(0, firstLineBreak).trim();
        const code = trimmedBlock.substring(firstLineBreak).trim();

        switch (blockType) {
          case 'html':
            newFiles['index.html'] = code;
            break;
          case 'css':
            newFiles['styles.css'] = code;
            break;
          case 'javascript':
          case 'js':
            newFiles['script.js'] = code;
            break;
        }
      });
      
      setFiles(newFiles);
      
      // Don't auto-update preview or trigger generation
      if (showPreview) {
        setShowPreview(false); // Switch back to code view
      }
    } catch (err) {
      console.error('Failed to paste:', err);
      toast.error('Failed to paste from clipboard');
    }
  };

  const handleClear = () => {
    setGeneratedCode('');
  };

  const handleCodeChange = (e) => {
    setGeneratedCode(e.target.value);
  };

  // Add loading state for preview
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  // Update the preview function
  const updatePreview = async () => {
    if (!user) {
      setError('Please sign in to use Code Convert');
      toast.error('Authentication required');
      return;
    }

    setIsPreviewLoading(true);
    try {
      const result = await generateCode({
        prompt,
        outputFormat,
        aspectRatio,
        files
      });
      
      if (result.data.preview) {
        setPreviewUrl(`data:image/${result.data.preview.type};base64,${result.data.preview.data}`);
      }
    } catch (error) {
      console.error('Preview update error:', error);
      if (error.code === 'unauthenticated') {
        setError('Please sign in to use Code Convert');
        toast.error('Authentication required');
      } else {
        toast.error('Failed to update preview');
      }
    } finally {
      setIsPreviewLoading(false);
    }
  };

  // Add refresh preview button in your JSX
  <button 
    type="button"
    className="code-control-btn" 
    onClick={() => updatePreview(files)}
    disabled={!files['index.html']}
    title="Refresh Preview"
  >
    <span className="refresh-icon">↻</span> Refresh
  </button>

  // Modify tab switching to not trigger preview update
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // Remove any auto-preview logic here
  };

  // Add preview toggle handler
  const togglePreview = () => {
    try {
      setShowPreview(!showPreview);
    } catch (error) {
      console.error('Preview toggle error:', error);
      setError('Failed to toggle preview. Please try again.');
    }
  };

  // Add fetchPromptHistory function
  const fetchPromptHistory = useCallback(async () => {
    if (!user) {
      console.log('No user logged in - skipping prompt history fetch');
      setPromptHistory([]);
      return;
    }

    try {
      console.log('Fetching prompt history for user:', user.uid);
      const promptsRef = collection(db, 'prompts');
      const q = query(
        promptsRef,
        where('userId', '==', user.uid),
        orderBy('timestamp', 'desc'),
        limit(10)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const prompts = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Fetched prompts:', prompts);
        setPromptHistory(prompts);
      }, (error) => {
        console.error('Error fetching prompts:', error);
        toast.error('Failed to fetch prompt history');
      });

      return unsubscribe;
    } catch (error) {
      console.error('Error setting up prompt listener:', error);
      toast.error('Failed to load prompt history');
    }
  }, [user]);

  // Add this useEffect to fetch prompt history on mount and user change
  useEffect(() => {
    const unsubscribe = fetchPromptHistory();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [fetchPromptHistory]);

  // Add state for conversion
  const [isConverting, setIsConverting] = useState(false);
  const [convertedContent, setConvertedContent] = useState(null);

  // Update the handleConvert function
  const handleConvert = async () => {
    if (!generatedCode) return;
    
    try {
      setIsConverting(true);
      setError(null);
      setPdfPreview(null);

      const response = await generateCode({
        prompt,
        outputFormat,
        aspectRatio,
        convert: true,
        files: {
          'content.md': generatedCode
        }
      });

      console.log('Conversion response:', response);

      if (response.data?.url) {
        setPdfPreview({
          url: response.data.url,
          filename: response.data.filename,
          content: response.data.content
        });
      } else {
        throw new Error('No preview URL returned');
      }
    } catch (error) {
      console.error('Conversion error:', error);
      setError(error.message);
      toast.error(error.message);
    } finally {
      setIsConverting(false);
    }
  };

  // Add useEffect to load recent prompts from localStorage
  useEffect(() => {
    const savedPrompts = localStorage.getItem('recentPrompts');
    if (savedPrompts) {
      try {
        setRecentPrompts(JSON.parse(savedPrompts));
      } catch (err) {
        console.error('Error loading recent prompts:', err);
      }
    }
  }, []);

  // Save recent prompts to localStorage when they change
  useEffect(() => {
    if (recentPrompts.length > 0) {
      localStorage.setItem('recentPrompts', JSON.stringify(recentPrompts));
    }
  }, [recentPrompts]);

  // Load prompt history from Firestore when component mounts
  useEffect(() => {
    if (!user) return;

    console.log('Loading prompt history for user:', user.uid);

    const promptsRef = collection(db, 'prompts');
    const q = query(
      promptsRef,
      where('userId', '==', user.uid),
      orderBy('timestamp', 'desc'),
      limit(5)
    );

    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        const prompts = snapshot.docs.map(doc => ({
          id: doc.id,
          prompt: doc.data().prompt,
          timestamp: doc.data().timestamp?.toDate(),
          outputFormat: doc.data().outputFormat,
          aspectRatio: doc.data().aspectRatio
        }));
        console.log('Loaded prompts:', prompts);
        setPromptHistory(prompts);
      },
      (error) => {
        console.error('Error loading prompts:', error);
        toast.error('Failed to load prompt history');
      }
    );

    return () => {
      console.log('Cleaning up prompt history listener');
      unsubscribe();
    };
  }, [user]);

  // Add disabled state for aspect ratio
  const isAspectRatioDisabled = outputFormat === 'pdf';

  // Update the useEffect for format detection
  useEffect(() => {
    if (!prompt) return;

    const promptLower = prompt.toLowerCase();
    
    // Check for format keywords
    for (const [format, keywords] of Object.entries(formatKeywords)) {
      if (keywords.some(keyword => promptLower.includes(keyword))) {
        console.log('Detected format:', format, 'from keyword in prompt');
        // Don't auto-select PDF format, and don't change if user manually selected
        if (format !== 'pdf' && !changingOptions.outputFormat) {
          setOutputFormat(format);
        }
      }
    }
  }, [prompt, changingOptions.outputFormat]);

  // Update the aspect ratio select element
  const renderAspectRatioSelect = () => {
    const options = [
      { value: '16:9', label: '16:9' },
      { value: '9:16', label: '9:16' },
      { value: '4:5', label: '4:5' },
      { value: '1:1', label: '1:1' }
    ];

    // Add Letter option only for PDF
    if (outputFormat === 'pdf') {
      options.push({ value: 'letter', label: 'Letter' });
    }

    return (
      <select
        id="aspectRatio"
        value={outputFormat === 'pdf' ? 'letter' : aspectRatio}
        onChange={handleAspectRatioChange}
        disabled={outputFormat === 'pdf'}
        className={`aspect-ratio-select ${outputFormat === 'pdf' ? 'disabled' : ''}`}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  };

  // Add state for generation loading
  const [isGenerating, setIsGenerating] = useState(false);

  // Update the renderPreview function
  const renderPreview = () => {
    if (isConverting) {
      return (
        <div className="loading-overlay">
          <MatrixSpiral />
        </div>
      );
    }

    if (outputFormat === 'pdf') {
      return (
        <div className="pdf-preview">
          {pdfPreview?.url ? (
            <iframe 
              src={pdfPreview.url}
              width="100%"
              height="600"
              title="PDF Preview"
              style={{ 
                border: 'none',
                backgroundColor: 'var(--matrix-dark)'
              }}
            />
          ) : (
            <div className="preview-placeholder">
              Generate a PDF to see preview
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="graphics-preview">
        {files['content.md'] ? (
          <div 
            className="preview-content"
            dangerouslySetInnerHTML={{ 
              __html: marked(files['content.md']) 
            }}
          />
        ) : (
          <div className="preview-placeholder">
            Generate content to see preview
          </div>
        )}
      </div>
    );
  };

  // Add styles for preview placeholder
  const previewPlaceholderStyles = `
    .preview-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
      color: var(--matrix-green);
      background-color: var(--matrix-dark);
      border: 1px solid var(--matrix-green);
      border-radius: 4px;
      font-family: 'Orbitron', sans-serif;
    }
  `;

  // Update the code generation section
  const renderCodeSection = () => {
    if (isGenerating) {
      return (
        <div className="terminal-animation">
          <div className="terminal-line">Generating code...</div>
          <div className="terminal-cursor"></div>
        </div>
      );
    }
    
    return (
      <textarea
        id="generated-code"
        name="generatedCode"
        value={generatedCode}
        onChange={handleCodeChange}
        placeholder="Generated code will appear here..."
        className="code-textarea"
        style={{ height: 'auto', minHeight: '400px' }}
      />
    );
  };

  // Add state for auth status
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Add effect to track auth status
  useEffect(() => {
    if (user) {
      console.log('User authenticated:', user.uid);
      setIsAuthenticated(true);
    } else {
      console.log('No user authenticated');
      setIsAuthenticated(false);
    }
  }, [user]);

  return (
    <ErrorBoundary>
      <div className="code-converter">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="converter-layout">
              <div className="input-output-container">
                <div className="input-section">
                  <div className="form-group">
                    <label htmlFor="prompt-input">Describe what you want to create:</label>
                    <textarea
                      id="prompt-input"
                      name="prompt"
                      value={prompt}
                      onChange={handlePromptChange}
                      required
                      placeholder="Describe what you want to create..."
                      className="prompt-textarea"
                    />
                  </div>

                  <div className="options-grid">
                    <div className="form-group">
                      <label htmlFor="output-format">Output Format:</label>
                      <select
                        id="output-format"
                        name="outputFormat"
                        value={outputFormat}
                        onChange={(e) => {
                          const newFormat = e.target.value;
                          setOutputFormat(newFormat);
                          triggerOptionAnimation('outputFormat', outputFormat, newFormat);
                        }}
                      >
                        <option value="image">Image</option>
                        <option value="pdf">PDF</option>
                        <option value="gif">Animated GIF</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="aspect-ratio">Aspect Ratio:</label>
                      <select
                        id="aspect-ratio"
                        name="aspectRatio"
                        value={outputFormat === 'pdf' ? 'letter' : aspectRatio}
                        onChange={handleAspectRatioChange}
                        disabled={outputFormat === 'pdf'}
                        className={outputFormat === 'pdf' ? 'disabled' : ''}
                      >
                        <option value="16:9">16:9</option>
                        <option value="9:16">9:16</option>
                        <option value="4:5">4:5</option>
                        <option value="1:1">1:1</option>
                        <option value="letter">Letter</option>
                      </select>
                    </div>
                  </div>

                  <div className="button-group">
                    <button 
                      className={`generate-button ${loading ? 'loading' : ''}`}
                      type="submit"
                      disabled={loading || !prompt.trim()}
                    >
                      {loading ? (
                        <span className="loading-text">GENERATING</span>
                      ) : (
                        'GENERATE CODE'
                      )}
                    </button>
                  </div>
                </div>

                <div className="output-section">
                  <div className="code-output-container">
                    <div className="code-section">
                      {renderCodeSection()}
                      <button
                        className="convert-button"
                        onClick={handleConvert}
                        disabled={!generatedCode || isConverting}
                      >
                        {isConverting ? 'CONVERTING...' : 'CONVERT CODE'}
                      </button>
                    </div>
                    
                    <div className="conversion-section">
                      {renderPreview()}
                    </div>
                  </div>
                </div>
              </div>

              <PromptHistory 
                history={promptHistory} 
                onPromptSelect={(item) => {
                  console.log('Selected prompt:', item); // Debug log
                  setPrompt(item.prompt);
                  if (item.outputFormat) setOutputFormat(item.outputFormat);
                  if (item.aspectRatio) setAspectRatio(item.aspectRatio);
                }}
              />
            </div>
          </form>
        </div>

        {error && <div className="error">{error}</div>}
      </div>
    </ErrorBoundary>
  );
};

export default CodeConverter; 