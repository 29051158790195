import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import './LoginPage.css';

function LoginPage() {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signInWithGoogle, user } = useAuth();
  
  useEffect(() => {
    // If user is already logged in, redirect to home
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    const lines = [
      '$ initializing system...',
      '$ loading security protocols...',
      '$ establishing secure connection...',
      '$ CODE CONVERT v1.0',
      '$ awaiting authentication...'
    ];
    
    let currentLine = 0;
    let currentChar = 0;
    let currentText = '';
    
    const typeText = () => {
      if (currentLine >= lines.length) return;
      
      const line = lines[currentLine];
      currentText += line[currentChar];
      setText(currentText);
      currentChar++;
      
      if (currentChar >= line.length) {
        currentText += '\n';
        setText(currentText);
        currentLine++;
        currentChar = 0;
      }
      
      setTimeout(typeText, 50);
    };
    
    typeText();
    
    return () => {
      currentText = '';
      setText('');
    };
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setError('');
      await signInWithGoogle();
      navigate('/');
    } catch (err) {
      console.error('Login error:', err);
      if (err.code !== 'auth/popup-closed-by-user') {
        setError('Failed to sign in with Google');
        toast.error('Failed to sign in with Google');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="terminal">
        <pre>{text}</pre>
        {error && <div className="error-message">{error}</div>}
        <button 
          onClick={handleGoogleSignIn}
          disabled={loading}
          className="login-button"
        >
          {loading ? 'Signing in...' : 'Sign in with Google'}
        </button>
      </div>
    </div>
  );
}

export default LoginPage; 