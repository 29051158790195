import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import LoginPage from './components/Auth/LoginPage';
import MainPage from './components/MainPage';
import MatrixRain from './components/common/MatrixRain';
import MatrixTitle from './components/common/MatrixTitle';
import './App.css';

function App() {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="matrix-loading">Initializing...</div>
      </div>
    );
  }

  return (
    <div className="App">
      <MatrixRain />
      <MatrixTitle />
      <Routes>
        <Route 
          path="/login" 
          element={
            user ? 
              <Navigate to="/" state={{ from: location }} replace /> : 
              <LoginPage />
          } 
        />
        <Route 
          path="/" 
          element={
            user ? 
              <MainPage /> : 
              <Navigate to="/login" state={{ from: location }} replace />
          } 
        />
        <Route 
          path="*" 
          element={<Navigate to="/" replace />} 
        />
      </Routes>
    </div>
  );
}

export default App;