import { 
  performanceOptimizer,
  analyticsSystem,
  errorRecovery,
  cacheManager,
  monitoringSystem 
} from './frontendSystems';

class SystemsManager {
  constructor() {
    this.systems = {
      performance: performanceOptimizer,
      analytics: analyticsSystem,
      errorRecovery: errorRecovery,
      cache: cacheManager,
      monitoring: monitoringSystem
    };
  }

  async initialize() {
    console.log('Initializing frontend systems...');
    
    try {
      // Initialize each system
      for (const [name, system] of Object.entries(this.systems)) {
        if (system && typeof system.init === 'function') {
          await system.init();
          console.log(`${name} system initialized`);
        }
      }
      
      console.log('All frontend systems initialized');
    } catch (error) {
      console.error('Failed to initialize systems:', error);
      throw error;
    }
  }

  getSystem(name) {
    return this.systems[name];
  }
}

const systemsManager = new SystemsManager();
export default systemsManager; 